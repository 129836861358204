<template>
  <v-container fluid pa-3>
    <v-row class="center">

      About Screen

    </v-row>
  </v-container>
</template>

<script>
console.log('//////////////////////////////////////');



export default {
  
};
</script>

